/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    @include desktop {
        --my-account-tab-list-background: #{$white};
        --my-account-tab-list-separator-color: rgba(10, 9, 3, 0.16);
    }
}

.MyAccountTabList {
    @include desktop {
        background-color: var(--my-account-tab-list-background);
        border: var(--my-account-content-border);
        padding: 12px 0;
    }

    &-ExpandableContentContent {
        @include desktop {
            margin-block-start: 0;
        }

        &_isContentExpanded {
            @include mobile {
                padding-block-start: 0;
                padding-block-end: 14px;
                padding-inline: 14px;
            }
        }
    }

    &-ExpandableContentButton {
        padding-inline: 16px;

        // @include desktop {
        //     display: none;
        // }

        &::before,
        &::after {
            inset-inline-end: 24px;

            @include mobile {
                inset-inline-end: 28px;
            }
        }
    }

    &-Separator {
        width: 90%;
        margin: auto;
        height: 1px;
        background-color: var(--my-account-tab-list-separator-color);
    }
}
