/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.LoginWithOtpForm {
    display: flex;
    margin-top: 10px;
    .Field-Wrapper {
        flex: 1;
    }
    .Field {
        margin-top: 0;
    }
    &Country {
        background: #dedede;
        padding: 10px;
        font-size: 18px;
        line-height: 24px;
    }
}
.MyAccountOverlay {
    &-AdditionalNewUser{
        display: grid !important;
        grid-template-columns: 1fr 1fr;
    }
}
